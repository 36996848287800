<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{operationTitle}}
                    </template>
                    <template v-slot:toolbar>
                        <b-input-group-append>
                            <router-link to="/roles/create">
                                <b-button class="btn btn-primary font-weight-bolder"
                                          v-if="$global.hasPermission('rolesstore')" v-b-tooltip.hover
                                          :title="`Add New`"
                                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                                </b-button>
                            </router-link>
                            <b-button
                                :class="checkFilterCount ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'"
                                v-b-toggle.sidebar-backdrop-roles>
                                <i class="fa fa-filter fa-sm"></i> Filter

                            </b-button>

                        </b-input-group-append>
                    </template>

                    <template v-slot:body>
                        <v-card>
                            <div class="roles-table">

                                <v-data-table
                                    :headers="columns"
                                    :items="dataSource"
                                    :items-per-page="parseInt(pagination.totalPerPage)"
                                    :loading="loading"
                                    class="table table-head-custom table-vertical-center table-responsive"
                                    hide-default-footer
                                    ref="table"
                                    responsive
                                >
                                    <template v-slot:item.id="record">
                                        {{dataSource.length - record.index}}
                                    </template>
                                    <template v-slot:item.permissions="{item}">
                                        <b-tag :key="index" color="blue"
                                               v-for="(permission, index) in item.permissions"> {{
                                            permission.name }}
                                        </b-tag>
                                    </template>
                                    <template class="action-column" v-slot:item.action="{item}">
                                        <router-link :to="`roles/edit/${item.id}`">
                                            <a class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                               size="sm"
                                               v-if="(item.id !== 3  && item.is_delete  && $global.hasPermission('rolesupdate'))">
                                                <span
                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                      class="action-edit-button"
                                                      src="/media/svg/icons/Communication/Write.svg"
                                                  ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </a>
                                        </router-link>
                                        <a @click="handleDeleteOperation(item.id)"
                                           class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                                           v-if="(item.id !== 3  && item.is_delete  && $global.hasPermission('rolesdestroy'))"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                  class="action-delete-button" src="/media/svg/icons/General/Trash.svg"
                                              ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </template>
                                </v-data-table>
                                <div class="row">
                                    <div class="pagination-box">
                                        <b-pagination
                                            :per-page="pagination.totalPerPage"
                                            :total-rows="pagination.total"
                                            size="lg"
                                            v-model="pagination.current"
                                        ></b-pagination>

                                    </div>
                                </div><!-- /.pull-right -->
                            </div>
                        </v-card>
                    </template>
                </KTCard>

                <template>
                    <div>
                        <b-sidebar
                            :backdrop-variant="`transparent`"
                            backdrop
                            id="sidebar-backdrop-roles"
                            right
                            shadow
                            title="Filters"
                        >
                            <template #header="{ hide }">
                                <b-col sm="10">
                                    <strong id="sidebar-backdrop-leads___title__">Filters</strong>
                                </b-col>
                                <b-col sm="2">
                                    <button @click="hide();"
                                            class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                                        <i class="ki ki-close icon-xs text-muted"></i>
                                    </button>
                                </b-col>
                            </template>
                            <template #footer="{ hide }">
                                <div
                                    class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
                                    <b-button @click="hide();handleResetFilterClick()"
                                              class="btn btn-light-primary font-weight-bolder ml-2">
                                        <i class="fa fa-broom fa-sm"></i> Clear All
                                    </b-button>
                                </div>
                            </template>
                            <div class="px-10 py-4">
                                <b-col sm="12">
                                    <b-form-group
                                        label="Name"
                                        label-for="name">
                                        <b-form-input class="mb-2" id="filter-name"
                                                      v-model="filters.name"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </div>
                        </b-sidebar>
                    </div>
                </template>
                <!-- /.filter-container -->
            </div>
        </div>

    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import ListingMixin from "@/assets/js/components/ListingMixin";
    import KTCard from "@/view/content/Card.vue";
    import ModuleMixin from "./module.mixin"

    const DEFAULT_FILTER_STATE = {
        name: null,
    };

    export default {
        mixins: [ListingMixin, ModuleMixin],
        data() {
            return {
                filters: {...DEFAULT_FILTER_STATE},
            }
        },
        components: {
            KTCard,
        },
        methods: {
            handleResetFilterClick() {
                this.filters = {...DEFAULT_FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.checkFilterCountStatus()
            },
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    };
</script>
